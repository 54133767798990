import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-Banner',
  templateUrl: './Banner.component.html',
  styleUrls: ['./Banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() fullheight: boolean;
  constructor() { }

  ngOnInit() {
  }

}
