import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-RulesPage',
  templateUrl: './RulesPage.component.html',
  styleUrls: ['./RulesPage.component.css']
})
export class RulesPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
