import { Component, OnInit } from '@angular/core';
import { faHome, faUsers, faCalendarAlt, faPowerOff, faListOl } from '@fortawesome/free-solid-svg-icons';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-Navigation',
  templateUrl: './Navigation.component.html',
  styleUrls: ['./Navigation.component.css']
})
export class NavigationComponent implements OnInit {
  faHome = faHome;
  faUsers = faUsers;
  faCalendarAlt = faCalendarAlt;
  faTeamspeak = faTeamspeak;
  faPowerOff = faPowerOff;
  faIdCard = faIdCard;
  faListOl = faListOl;
  
  constructor() { }

  ngOnInit() {
  }

}
