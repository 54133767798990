import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-EventsPage',
  templateUrl: './EventsPage.component.html',
  styleUrls: ['./EventsPage.component.css']
})
export class EventsPageComponent implements OnInit {
  events: any;

  constructor( private api: ApiService ) { }

  ngOnInit() {
    this.loadEvents();
  }

  loadEvents() {
    this.api.get( 'events', 
    ( response: any ) => {
      this.events = response.data.events;
    },
    ( error: any ) => {
      this.events = false;
    } );
    }

}
