import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor( private httpservice: HttpClient ) { }

  getTestResult () {
    this.httpservice.get( 'https://api.djdiavolo.local/members' )
    .subscribe( ( response: any ) => {
      if ( response.http_code === 200 )
      {
        return response.data;
      }
      return [];
    } );
  }

}
