import { Component, OnInit } from '@angular/core';
import { faUsers, faCalendarAlt, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-HomeArticles',
  templateUrl: './HomeArticles.component.html',
  styleUrls: ['./HomeArticles.component.css']
})
export class HomeArticlesComponent implements OnInit {
  faUsers = faUsers;
  faTeamspeak = faTeamspeak;
  faCalendarAlt = faCalendarAlt;
  faPowerOff = faPowerOff;
  faIdCard = faIdCard;
  constructor() { }

  ngOnInit() {
  }

}
