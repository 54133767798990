import { Component, OnInit } from '@angular/core';
import { faSteam, faInstagram, faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-Footer',
  templateUrl: './Footer.component.html',
  styleUrls: ['./Footer.component.css']
})
export class FooterComponent implements OnInit {
  faSteam = faSteam;
  faInstagram = faInstagram;
  faTeamspeak = faTeamspeak;
  showGetMemberSection = true;

  constructor( private router: Router ) {
    router.events.subscribe( (e) => {
      if ( e instanceof NavigationEnd )
      {
        this.showGetMemberSection = !(e.urlAfterRedirects === '/community/join');
      }
    } );
   }

  ngOnInit() { }

}
