import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/HomePage/HomePage.component';
import { AboutUsPageComponent } from './pages/AboutUsPage/AboutUsPage.component';
import { AGBPageComponent } from './components/AGBPage/AGBPage.component';
import { ImpressumPageComponent } from './pages/ImpressumPage/ImpressumPage.component';
import { CommunityPageComponent } from './pages/CommunityPage/CommunityPage.component';
import { TeamspeakPageComponent } from './pages/TeamspeakPage/TeamspeakPage.component';
import { EventsPageComponent } from './pages/EventsPage/EventsPage.component';
import { ServersPageComponent } from './pages/ServersPage/ServersPage.component';
import { ContactPageComponent } from './pages/ContactPage/ContactPage.component';
import { CommunityJoinPageComponent } from './pages/CommunityJoinPage/CommunityJoinPage.component';
import { RulesPageComponent } from './pages/RulesPage/RulesPage.component';

const routes: Routes = [
  {path: 'rules', component: RulesPageComponent},
  {path: 'community', component: CommunityPageComponent},
  {path: 'community/join', component: CommunityJoinPageComponent},
  {path: 'teamspeak', component: TeamspeakPageComponent},
  {path: 'events', component: EventsPageComponent},
  {path: 'servers', component: ServersPageComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'impressum', component: ImpressumPageComponent},
  {path: 'agb', component: AGBPageComponent},
  {path: 'about-us', component: AboutUsPageComponent},
  {path: '**', component: HomePageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
