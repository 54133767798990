import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/HomePage/HomePage.component';
import { BannerComponent } from './components/Banner/Banner.component';
import { FooterComponent } from './components/Footer/Footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AboutUsPageComponent } from './pages/AboutUsPage/AboutUsPage.component';
import { AboutUsComponent } from './components/AboutUs/AboutUs.component';
import { AGBPageComponent } from './components/AGBPage/AGBPage.component';
import { ImpressumPageComponent } from './pages/ImpressumPage/ImpressumPage.component';
import { AGBComponent } from './components/AGB/AGB.component';
import { ImpressumComponent } from './components/Impressum/Impressum.component';
import { HomeArticlesComponent } from './components/HomeArticles/HomeArticles.component';
import { TeamspeakPageComponent } from './pages/TeamspeakPage/TeamspeakPage.component';
import { ServersPageComponent } from './pages/ServersPage/ServersPage.component';
import { NavigationComponent } from './components/Navigation/Navigation.component';
import { CommunityPageComponent } from './pages/CommunityPage/CommunityPage.component';
import { EventsPageComponent } from './pages/EventsPage/EventsPage.component';
import { ContactPageComponent } from './pages/ContactPage/ContactPage.component';
import { CommunityJoinPageComponent } from './pages/CommunityJoinPage/CommunityJoinPage.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TestService } from './services/TestService/Test.service';
import { LoadingComponent } from './ui/Loading/Loading.component';
import { RulesPageComponent } from './pages/RulesPage/RulesPage.component';

@NgModule({
   declarations: [
      AppComponent,
      HomePageComponent,
      BannerComponent,
      FooterComponent,
      AboutUsPageComponent,
      AboutUsComponent,
      AGBPageComponent,
      ImpressumPageComponent,
      AGBComponent,
      ImpressumComponent,
      HomeArticlesComponent,
      CommunityPageComponent,
      TeamspeakPageComponent,
      EventsPageComponent,
      ServersPageComponent,
      ContactPageComponent,
      RulesPageComponent,
      CommunityJoinPageComponent,
      NavigationComponent,
      LoadingComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      FontAwesomeModule,
      HttpClientModule
   ],
   providers: [
      HttpClient,
      TestService
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
