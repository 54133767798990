import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ImpressumPage',
  templateUrl: './ImpressumPage.component.html',
  styleUrls: ['./ImpressumPage.component.css']
})
export class ImpressumPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
