import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-AboutUs',
  templateUrl: './AboutUs.component.html',
  styleUrls: ['./AboutUs.component.css']
})
export class AboutUsComponent implements OnInit {
  @Input() classes: string;
  constructor() { }

  ngOnInit() {
  }

}
