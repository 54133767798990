import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ServersPage',
  templateUrl: './ServersPage.component.html',
  styleUrls: ['./ServersPage.component.css']
})
export class ServersPageComponent implements OnInit {
  servers:any;
  constructor( private api: ApiService ) { }

  ngOnInit() {
    this.loadServers();
  }

  loadServers() {
    this.api.get( 'servers', 
    ( response: any ) => {
      this.servers = response.data.servers;
    },
    ( error: any ) => {
      this.servers = false;
    } );
    }
}
