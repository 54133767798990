import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-CommunityPage',
  templateUrl: './CommunityPage.component.html',
  styleUrls: ['./CommunityPage.component.css']
})
export class CommunityPageComponent implements OnInit {
  members: any;
  faUserCircle = faUserCircle;

  constructor(private service: HttpClient, private api: ApiService) { }

  ngOnInit() {
    this.loadMembers();
  }

  loadMembers() {
  this.api.get( 'members', 
  ( response: any ) => {
    this.members = response.data.members;
  },
  ( error: any ) => {
    this.members = false;
  } );
  }

}
