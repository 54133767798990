import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TGOA-Gaming';

  constructor( private router: Router ) {
    router.events.subscribe( (e) => {
      window.scrollTo(0, 0);
    } );
   }

}