import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ContactPage',
  templateUrl: './ContactPage.component.html',
  styleUrls: ['./ContactPage.component.css']
})
export class ContactPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
