import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  @Output() onSuccess;

  constructor( private service: HttpClient ) { }

  get( address: String, onSuccess: any, onError: any ) {

    this.service.get( environment.apiBaseUrl + '/' + address, {observe: 'response'} )
    .subscribe( response => {
      if ( typeof onSuccess === 'function' )
      {
        onSuccess( response.body );
        return true;
      }
    }, error => {
      if ( typeof onError === 'function' )
      {
        onError( error );
        return false;
      }
    } );

  }

}
